/**
 * Dies ist ein Beispiel-Code, der Daten von einer API lädt, sie in Tabellen formatiert und als Excel-Datei exportiert.
 * Die Daten werden gruppiert und gefiltert und können vom Benutzer ausgewählt werden.
 * Der Code verwendet React, TypeScript, ExcelJS und axios.
 * Autor: Alessandro Santos Schmidt (Praktikant)
 * 
 */


import { useState, useEffect } from "react";
import "./Home.css";
import React from "react";

import { loadData } from "./loadData";
import { exportGoogleSheets } from "./exportGoogleSheets";
import { exportPDF } from "./exportPDF";
import { isAuthorized } from "./isAuthorized";

interface HomeProps {
  token: string;
}

interface Entry {
  cantine: string;
  meal: string;
  received: boolean;
  takeaway: boolean;
  time: string;
  half: boolean;
  userDisplayName: string;
}

declare global {
  interface Date {
    getWeek(): number;
  }
}

// eslint-disable-next-line no-extend-native
Date.prototype.getWeek = function () {
  const firstDayOfYear = new Date(this.getFullYear(), 0, 1);
  const daysOffset = firstDayOfYear.getDay() === 0 ? 1 : 0;
  const firstMondayOfYear = new Date(this.getFullYear(), 0, 1 + daysOffset);
  const diff = this.getTime() - firstMondayOfYear.getTime();
  const dayOfYear = Math.floor(diff / 86400000);
  return Math.ceil((dayOfYear + firstMondayOfYear.getDay() + 1) / 7);
};

// Definiere eine React-Komponente "Home", die das UI rendert und Daten von der API lädt
function Home({ token }: HomeProps) {
  // Definiere verschiedene Zustandsvariablen, die von der Komponente verwendet werden
  const [data, setData] = useState<Entry[]>([]); // Speichert die Daten, die von der API geladen werden
  const [selectedCanteen, setSelectedCanteen] = useState<string | null>(null); // Speichert die ausgewählte Kantine
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date()); // Setze das heutige Datum als Standardwert
  const [selectedTime, setSelectedTime] = useState<string | null>(null); // Speichert die ausgewählte Uhrzeit
  const [selectedTakeaway] = useState<boolean | null>(
    null
  ); // Speichert den ausgewählten Takeaway-Filter
  const [mealsPerWeek] = useState<Record<string, number>>({});
  const [, setAuthorized] = useState(false);
  
  const [notInit, setNotInit] = useState(true);
  // Verwende den Hook "useEffect" um die Funktion "seeData" beim Laden der Komponente auszuführen
  useEffect(() => {
    async function runApi() {
      let isAuth = await isAuthorized(token);
      setAuthorized(isAuth);
      if(isAuth){
        console.log("Loading")
        loadData(selectedDate, token, setData);
      }else{
        window.location.replace("https://kaercher.net/home");
      }
    }
  
    if (notInit) {
      setNotInit(false);
      runApi();
    }
    const intervalId = setInterval(runApi, 60000); // in milliseconds
    return () => clearInterval(intervalId);
  }, [notInit, selectedDate, token]); // Gib ein leeres Array als zweites Argument an, um sicherzustellen, dass der Hook nur einmal ausgeführt wird. Hier wird keine Abhängigkeit angegeben, da die Funktion nur einmalig aufgerufen werden soll.

  useEffect(() => {
    setData([])
  },[])


  const filteredData = data
    .filter((entry) => {
      if (selectedCanteen && entry.cantine !== selectedCanteen) return false;
      if (selectedTime && entry.time !== selectedTime) return false;
      if (selectedTakeaway !== null && entry.takeaway !== selectedTakeaway)
        return false; // Hinzufügen der Bedingung für den ausgewählten Takeaway-Filter
      return true;
    })
    .reduce((groups: Record<string, Entry[]>, entry) => {
      // Reduziere die gefilterten Daten auf eine Gruppierung nach Zeit
      const time = entry.time;
      if (!groups[time]) groups[time] = [];
      groups[time].push(entry); // Füge den Eintrag zur entsprechenden Zeit-Gruppe hinzu
      return groups;
    }, {});

  // Definiere die Home-Komponente
  return (
    <div className="Home">
      <h1>Mahlzeit</h1>
      <div className="row">
        <div className="col-sm-4">
          <div className="dropdown-container">
            <label htmlFor="canteen-select">Kantine:</label>
            <select
              id="canteen-select"
              className="form-select dropdown"
              value={selectedCanteen || ""}
              onChange={(e) => {
                const canteen = e.target.value || null;
                setSelectedCanteen(canteen);
              }}
            >
              <option value="">Alle Kantinen</option>
              <option value="Cantine Winnenden">Kantine Winnenden</option>
              <option value="Cantine Schwaikheim">Kantine Schwaikheim</option>
              <option value=" Cantine KVD">Kantine KVD (erstes go live ) </option>
            </select>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="dropdown-container">
            <label htmlFor="date-select">Datum:</label>
            <input
              id="date-select"
              className="form-control dropdown"
              type="date"
              value={selectedDate ? selectedDate.toISOString().substr(0, 10) : ""}
              onChange={(e) => {
                const date = e.target.value ? new Date(e.target.value) : null;
                setSelectedDate(date);
                if (date) {
                  loadData(date, token, setData);
                }
              }}
              min="2023-01-01"
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="dropdown-container">
            <label htmlFor="time-select">Uhrzeit:</label>
            <select
              id="time-select"
              className="form-select dropdown"
              onChange={(e) => {
                const time = e.target.value;
                setSelectedTime(time);
              }}
            >
               <option value="">Alle Uhrzeiten</option>
              <option value="11:30 - 12:00">11:30 - 12:00</option>
              <option value="12:00 - 12:30">12:00 - 12:30</option>
              <option value="12:30 - 13:00">12:30 - 13:00</option>
              <option value="13:00 - 13:15">13:00 - 13:15</option>
            </select>
          </div>
        </div>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
          </tr>
        </thead>
        <tbody>
          {Object.entries(mealsPerWeek).map(([week, count]) => (
            <tr key={week}>
              <td>{week}</td>
              <td>{count}</td>
            </tr>
          ))}
        </tbody>
      </table>
      
      <div className="table-responsive">
  {Object.entries(filteredData)
    .sort(([a], [b]) => a.localeCompare(b))
    .map(([time, entries], index, array) => (
      <React.Fragment key={time}>
        {entries.some((entry) => entry.takeaway && entry.time !== "12:00") && <h2>Takeaway-Gerichte</h2>} {/* Text anzeigen, wenn eine Takeaway-Bestellung vorhanden ist */}
        <h2>{time}</h2>
        <table className="table table-striped table-full-width">
          <thead>
            <tr>
              <th>Mitarbeitername</th>
              <th>Gericht</th>
              <th>Anzahl</th>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, index) => (
              <tr key={index}>
                <td>{entry.userDisplayName}</td>
                <td>{entry.meal}</td>
                <td>1</td>
              </tr>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    ))}
</div>

<button
      className="btn btn-primary"
      onClick={() => exportGoogleSheets(data, selectedDate)}
    >
      Daten exportieren Google Sheets
    </button>
    <button
      className="btn btn-primary"
      onClick={() => exportPDF(data, selectedDate)}
    >
      Daten exportieren PDF
    </button>
  </div>
);

}
export default Home;