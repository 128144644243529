import axios from "axios";
export async function isAuthorized(token: string) {
  const apiUrl = process.env.REACT_APP_BACKEND;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const result = await axios.get(
    `${apiUrl}/Authorization/IsAuthorizedCanteen`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
   return true;
}
