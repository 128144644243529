import axios from "axios";

interface Entry {
  cantine: string;
  meal: string;
  received: boolean;
  takeaway: boolean;
  time: string;
  half: boolean;
  userDisplayName: string; // Mitarbeitername
}

export async function loadData(
  date: Date | null,
  token: string,
  setData: (data: Entry[]) => void
) {
  const apiUrl = process.env.REACT_APP_BACKEND;
  const today = new Date().toISOString().substr(0, 10);
  const dateStr = date ? date.toISOString().substr(0, 10) : today;

  try {
    const result = await axios.get(
      `${apiUrl}/Meals/GetMealsDay?date=${dateStr}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const tmpData: Entry[] = result.data.map((element: any) => ({
      cantine: element.cantine,
      meal: element.meal,
      received: element.received,
      takeaway: element.takeaway,
      time: element.time,
      half: element.half,
      userDisplayName: element.userDisplayName, // Mitarbeitername aus der API
    }));

    setData(tmpData);
  } catch (error) {
    console.error("Error loading data:", error);
  }
}